<template>
  <div>
    <h1 class="title">Find Order</h1>
    <p>Searching here via email is the quickest way to search a long way back in time, or via postcode for a date range. More detailed search can be found on the fulfilment page!</p>
    <br />
    <p>When searching via postcode, the max date range can be 31 days.</p>
    <br />

    <div class="columns">
      <div class="column is-one-fifth">
        <div class="field">
          <label class="label">Search By</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model.trim="searchBy" v-on:change="changeSearchBy">
                <option value="email">Email</option>
                <option value="postcode">Postcode</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">
            {{
            searchBy === "email" ? "Email" : "Postcode"
            }}
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="searchByText"
              v-on:keyup.enter="updateFilter"
              v-on:change="updateFilter"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showMissingFieldsMessage" class="notification is-warning">
      Enter an
      <b>{{ searchBy === "email" ? "Email" : "Postcode" }}</b> to begin search
    </div>

    <div v-if="isLoading">
      <a class="button is-large is-loading">Loading</a>
      <br />
      <br />
    </div>
    <table
      v-else-if="mergedFulfilments !== null && mergedFulfilments.length > 0"
      class="table is-size-6 is-fullwidth"
    >
      <tr>
        <th>Location</th>
        <th>Id</th>
        <th>Email</th>
        <th>Full Name</th>
        <th>Billing Post code</th>
        <th>Order/Lead</th>
        <th>Shortsite</th>
        <th>Fulfilled</th>
        <th>Test</th>
        <th>Created</th>
        <th></th>
      </tr>
      <tr v-for="(r, index) in mergedFulfilments" :key="index">
        <td>
          <span class="tag is-warning" v-if="r.location == 'Legacy'">
            {{
            r.location
            }}
          </span>
          <span class="tag is-success" v-else>{{ r.location }}</span>
        </td>
        <td>{{ r.locationId }}</td>
        <td>{{ r.email }}</td>
        <td>{{ r.firstName }} {{ r.lastName }}</td>
        <td>{{ r.billingPostcode }}</td>
        <td>{{ r.isOrder ? "Order" : "Lead" }}</td>
        <td>
          <router-link
            title="View product config for this shortsite"
            v-if="r.location != 'Legacy' && hasFullAccess()"
            :to="`product-config?search=${r.shortsite}`"
          >{{ r.shortsite }}</router-link>
          <span v-else>{{ r.shortsite }}</span>
        </td>
        <td>{{ r.fulfilled ? "Yes" : "No" }}</td>
        <td>
          <span v-if="r.test" class="tag is-danger">Yes</span>
        </td>
        <td>{{ r.created }}</td>
        <td class="has-text-right">
          <router-link
            class="button is-info"
            :to="`view-user-details/${r.locationId}`"
          >View</router-link>
        </td>
      </tr>
    </table>

    <div v-else>No Results found</div>

  </div>
</template>
<script>
import { baseMixin } from "../../mixins/baseMixin.js";
export default {
  mixins: [baseMixin],
  data: function() {
    const today = new Date();
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      EnvironmentIsLive: process.env.VUE_APP_ENVIRONMENTISLIVE,
      size: 10,
      currentPaginationPage: 0,
      newFulfilments: null,
      modernFulfilments: null,
      query: "",
      isLoading: false,
      searchBy: "",
      searchByText: "", //sarahphillips8@sky.com
      showMissingFieldsMessage: false
    };
  },
  created() {
    this.searchBy = this.$route.query.by || "email"; //default
    this.searchByText = this.$route.query.query || ""; //defualt

    this.updateUrlAndLoadPage(true);
  },
  methods: {
    changeSearchBy() {
      this.searchByText = "";

      this.updateFilter();
    },
    updateUrlAndLoadPage(replace) {
      var url = "search-legacy-new?by=" + this.searchBy;
      url += "&query=" + this.searchByText;

      if (replace) this.$router.replace(url);
      else this.$router.push(url);

      this.loadFulfilments();
    },

    updateFilter() {
      this.updateUrlAndLoadPage(false);
    },
    paginatePage() {},
    loadFulfilments() {
      if (this.getHasRequiredFields()) {
        this.showMissingFieldsMessage = false;
        var that = this;
        this.isLoading = true;

        var urlModern = this.getModernAPI;
            this.axios
              .get(urlModern)
              .then(response => {
                this.modernFulfilments = response.data;
              })
              .catch(function(error) {
                that.$toast.open("Failed to load modern API: " + error);
              })
              .finally(function() {
                that.isLoading = false;
              });

        // fulfilment/legacycomparesearch
      } else {
        this.modernFulfilments = null;
        this.showMissingFieldsMessage = true;
      }
    },

    getHasRequiredFields() {
      return this.searchByText !== "";
    },
    getDateAsISOFormat(date) {
      //=2019-05-01
      var d = date
        .getDate()
        .toString()
        .padStart(2, "0");
      var m = (date.getMonth() + 1).toString().padStart(2, "0");
      var y = date.getFullYear();
      return `${y}-${m}-${d}`;
    },

  },

  computed: {
    mergedFulfilments() {
      if (this.modernFulfilments !== null) {
        var modernMapped = this.modernFulfilments.Results.map(function(f) {
          return {
            billingAddress1:
              f.DataObject.billing_contact.billing_contact_address1,
            billingAddress2:
              f.DataObject.billing_contact.billing_contact_address2,
            billingAddress3:
              f.DataObject.billing_contact.billing_contact_address3,
            billingCountry:
              f.DataObject.billing_contact.billing_contact_country,
            billingCounty: f.DataObject.billing_contact.billing_contact_county,
            billingPostcode:
              f.DataObject.billing_contact.billing_contact_postcode,
            billingTown: f.DataObject.billing_contact.billing_contact_town,
            email: f.DataObject.billing_contact.billing_contact_email,
            firstName: f.DataObject.billing_contact.billing_contact_first_name,
            lastName: f.DataObject.billing_contact.billing_contact_last_name,
            location: "New",
            locationId: f.Id,
            price: f.DataObject.products_info.offer_price,
            test: f.DataObject.meta_info.fulfilment_flags.is_test,
            fulfilled:
              f.StatusString === "AssignedBatch" ||
              f.StatusString === "SendSuccess",
            isOrder: f.DataObject.meta_info.is_lead === 0,
            shortsite: f.FourLetterCode,
            created: f.CreatedAt,
            model: f
          };
        });
        return modernMapped;
      }

      return null;
    },
    getModernAPI() {
      var url =
        this.APIUrl +
        `/fulfilment/legacycomparesearch?searchBy=${this.searchBy}&searchTerm=${this.searchByText}`;
      return url;
    },
  }
};
</script>
